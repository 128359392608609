'use client';

import { useCallback } from 'react';

import { useRouter } from 'next/navigation';

import { ErrorPage } from '@/components/error-page';
import type { Translations } from '@/types';

export function ClientNotFound({
    translations,
}: {
    translations: Translations;
}) {
    const router = useRouter();

    const handleNavigateHome = useCallback(() => {
        router.push('/');
    }, [router]);

    return (
        <ErrorPage
            title={translations.error.notFound.title}
            description={translations.error.notFound.description}
            actionLabel={translations.error.notFound.backHome}
            onAction={handleNavigateHome}
            translations={translations}
        />
    );
}
